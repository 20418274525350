import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
// import { clearSession } from "../../utils/session";

// export const userDelete = createAsyncThunk("user/userDelete", async (data, extra) => {
//   const res = await http.delete(`/user_profile/backend/delete_account`, {
//     headers: {
//       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data.data;
//   }
// });

// Upload and update user profile image
export const uploadUserProfileImage = createAsyncThunk("/user/uploadUserProfileImage", async (profileImage, extra) => {
  const formData = new FormData();
  formData.append("profile_image", profileImage);

  const res = await http.post(`/user_profile/backend/upload-profile-image/`, formData, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Update User Profile
export const updateUserProfile = createAsyncThunk("user/updateUserProfile", async (data, extra) => {
  const res = await http.put(`/online_store/customer/create/`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//Find location based on Zipcode
export const searchZipcode = createAsyncThunk("user/searchZipcode", async (data, extra) => {
  const res = await http.get(`/user_profile/api/zipcode/${data}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    id: 18,
    customer_name: "HINDUSTAN COPPER PVT LTD",
    address: "TCP E-33-36 MIDC PANVEL   India",
    zipcode: "410208",
    phone_number: "8982525106",
    gst_number: "27AAACH7409R1Z1",
    favourite: true,
    email: "",
    is_active: true,
    created_at: "2024-08-23T14:27:45.697694",
    updated_at: "2024-08-23T14:27:45.697694",
    user_profile: 6,
    business_profile: 3,
    profile_picture: null,
  },
  reducers: {
    setCustomerDetails: (state, action) => {
      sessionStorage.setItem("customer", action.payload);
      return { ...state, ...action.payload };
    },
    updateData: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setProfileImage: (state, action) => {
      sessionStorage.setItem("profile_image", `${action.payload}`);
      return { ...state, profile_picture: action.payload };
    },
    localStorgeProfileCache: (state, action) => {
      const Image_url = sessionStorage.getItem("profile_image");
      return { ...state, profile_picture: Image_url ?? null };
    },
    //TODO - remove if no dependency
    // updateBusinessDetails: (state, action) => {
    //   return { ...state, ...action.payload };
    // },
    removeBusinessDetails: (state, action) => {
      state = {}; // initial value here
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(userDelete.fulfilled, (state, action) => {
    //   clearSession();
    //   window.location.reload();
    // });
    builder.addCase(uploadUserProfileImage.fulfilled, (state, action) => {
      sessionStorage.setItem("profile_image", action.payload?.image_url);
      return { ...state, profile_picture: action.payload?.image_url };
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

export default userSlice.reducer;
export const { setCustomerDetails, setProfileImage, localStorgeProfileCache, updateData, removeBusinessDetails } =
  userSlice.actions;
// export const { updateBusinessDetails } = userSlice.actions;
