import React, { useEffect, useState } from "react";
import { http } from "../../service/http";
import { useDispatch } from "react-redux";
import { setCustomerDetails } from "../../reducers/Slices/userSlice";
import { useNavigate, useParams } from "react-router-dom";
// import GSTScreen from "../SharedComponents/GSTScreen";

import { searchZipcode } from "../../reducers/Slices/userSlice";
import toast from "react-hot-toast";
// import GSTScreenMandatory from "../SharedComponents/GSTScreenMandatory";
import GSTScreen from "../SharedComponents/GSTScreen";

export default function CreateCustomerProfileModal() {
  const { uniqueLinkCode } = useParams();

  const [formData, setFormData] = useState({ unique_link_code: uniqueLinkCode });
  // const [menus, setMenus] = useState({ industries: [], businesses: [] });
  const [showGstTab, setShowGstTab] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   async function fetchData() {
  //     await Promise.all([getIndustriesMenu(), getBusinessTypeMenu()]);
  //   }
  //   fetchData();
  //   // eslint-disable-next-line
  // }, []);

  // const fetchData = async (url, key) => {
  //   try {
  //     const response = await http.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //       },
  //     });
  //     if (response.data.status === "success") {
  //       setMenus((prevMenus) => ({ ...prevMenus, [key]: response.data.data }));
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getIndustriesMenu = () => {
  //   fetchData("/master_menu/api/industry/create", "industries");
  // };

  // const getBusinessTypeMenu = () => {
  //   fetchData("/master_menu/api/businesstype/list_create", "businesses");
  // };

  const handleSearchZipcode = async () => {
    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            // state: action.payload.State,
            // city: action.payload.District,
            address: action.payload.address2,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleChange = (e, data) => {
    e.preventDefault();
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let updatedFormData = { ...formData };

    //Check if gst number exists - if not then add empty gst number
    if (!formData.gst_number) {
      updatedFormData = { ...updatedFormData, gst_number: null };
    }

    try {
      const response = await http.post(`/online_store/customer/create/`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.data.status === "success") {
        dispatch(setCustomerDetails(response.data.data));
        navigate(`/${uniqueLinkCode}/`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {showGstTab ? (
        <GSTScreen setShowGstTab={setShowGstTab} formData={formData} setFormData={setFormData} />
      ) : (
        <div className="absolute h-[dvh] bg-white flex  justify-center top-0 left-0 px-4 w-full max-h-full">
          <div className="relative max-w-md overflow-y-scroll">
            <div className="pt-[20px] md:pt-[40px] ">
              <h3 className="text-xl font-semibold text-gray-900 w-full text-center">Create Account</h3>
            </div>
            <form className="p-4 md:p-5" onSubmit={handleSubmit}>
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className="col-span-2">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Email id*
                  </label>
                  <input
                    type="email"
                    onChange={(e) => handleChange(e, { email: e.target.value })}
                    value={formData.email}
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="name@profitai.com"
                    required
                  />
                </div>
                <div className="col-span-2">
                  <label htmlFor="phone_number" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Phone Number*
                  </label>
                  <input
                    type="tel"
                    onChange={(e) => handleChange(e, { phone_number: e.target.value })}
                    value={formData.phone_number}
                    name="phone_number"
                    id="phone_number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    maxLength={10}
                    required
                  />
                </div>
                <div className="col-span-2">
                  <label htmlFor="business_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Customer Name*
                  </label>
                  <input
                    type="text"
                    onChange={(e) => handleChange(e, { customer_name: e.target.value })}
                    value={formData.business_name}
                    name="business_name"
                    id="business_name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Enter Business name"
                    required
                  />
                </div>
                <div className="col-span-2 flex w-full">
                  <div className="relative z-0 w-full group">
                    <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                      Zipcode*
                    </label>
                    <div className="flex gap-[10px] ">
                      <input
                        type="number"
                        min={0}
                        id="zipcode"
                        onChange={(e) => handleChange(e, { zipcode: e.target.value })}
                        value={formData.zipcode}
                        className="flex-1 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Enter zipcode"
                        required
                      />
                      <button
                        type="button"
                        onClick={handleSearchZipcode}
                        className="px-[20px] py-[10px] rounded-lg bg-black text-white"
                      >
                        Find
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="col-span-2  flex">
                  <div className="w-1/2 px-1">
                    {" "}
                    <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 ">
                      State
                    </label>
                    <input
                      type="state"
                      id="state"
                      value={formData.state}
                      onChange={(e) => handleChange(e, { state: e.target.value })}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Enter zipcode"
                      required
                    />
                  </div>
                  <div className="w-1/2 px-1">
                    {" "}
                    <label htmlFor="city" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                      City
                    </label>
                    <input
                      type="city"
                      id="city"
                      value={formData.city}
                      onChange={(e) => handleChange(e, { city: e.target.value })}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Enter zipcode"
                      required
                    />
                  </div>
                </div> */}
                <form className="col-span-2 ">
                  <label htmlFor="address2" className="block mb-2 text-sm font-medium">
                    Address* (Max 250 Characters)
                  </label>
                  <textarea
                    id="address1"
                    value={formData.address}
                    rows="2"
                    maxLength={250}
                    onChange={(e) => handleChange(e, { address: e.target.value })}
                    className="block p-2.5 w-full text-sm   rounded-lg border border-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Address 1..."
                  ></textarea>
                </form>
                {/* <div className="col-span-2">
                  <label htmlFor="industry" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Industry Type*
                  </label>
                  <select
                    id="industry"
                    value={formData.industry}
                    onChange={(e) => handleChange(e, { industry: parseInt(e.target.value) })}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                  >
                    {menus.industries.map((item) => (
                      <option key={item.id + item.industry_name} value={`${item.id}`}>
                        {item.industry_name}
                      </option>
                    ))}
                  </select>
                </div> */}
                {/* <div className="col-span-2">
                  <label htmlFor="business_type" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Business Type*
                  </label>

                  <select
                    id="business_type"
                    value={formData.business_type}
                    onChange={(e) => handleChange(e, { business_type: parseInt(e.target.value) })}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                  >
                    {menus.businesses.map((item) => (
                      <option key={item.id + item.business_type} value={`${item.id}`}>
                        {item.business_type}
                      </option>
                    ))}
                  </select>
                </div> */}
              </div>
              <div className="w-full flex justify-center py-[10px]">
                <button
                  type="submit"
                  // onClick={handleSubmit}
                  className="px-[20px] py-[10px] bg-black text-white rounded-full"
                >
                  Create Account
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
