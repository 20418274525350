import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchZipcode, updateUserProfile, uploadUserProfileImage, userDelete } from "../../reducers/Slices/userSlice";
import profileDefault from "../../assets/images/profile-default.jpg";
import toast from "react-hot-toast";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [profileImg, setProfileImg] = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const { uniqueLinkCode } = useParams();

  const data = useSelector((state) => state.user);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitButton(true);
    dispatch(updateUserProfile({ ...formData, unique_link_code: uniqueLinkCode }))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          navigate(-1);
        } else {
          toast.error("Sorry, user profile cannot be updated");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const handleSearchZipcode = (e) => {
    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            // state: action.payload.State,
            // city: action.payload.District,
            address: action.payload.address,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleChangeProfileImage = (e) => {
    e.preventDefault();
    if (!profileImg) {
      toast.error("first select an image!");
      return;
    }
    dispatch(uploadUserProfileImage(profileImg))
      .then((action) => {
        if (action.payload) {
        } else {
          toast.error("Sorry, Profile Image cannot be changed");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // const handleDeleteAccount = () => {
  //   dispatch(userDelete());
  // };

  return (
    <div className="p-4 md:w-[76.5vw] min-[1124px]:min-w-[84vw] w-screen">
      <div className="flex justify-center">
        <form className="md:w-4/6 w-full mx-auto" onSubmit={handleSubmit}>
          <div>
            <div className="grid justify-items-center">
              <Avatar
                alt={formData.business_name}
                src={data?.profile_picture ? data?.profile_picture : profileDefault}
                sx={{ width: 150, height: 150 }}
              />
            </div>
            <div className="mt-5 mb-5 flex items-center flex-col">
              <label htmlFor="profile_image" className="block mb-2 text-sm font-medium text-gray-900">
                Profile Image
              </label>
              <input
                type="file"
                id="profile_image"
                accept="image/*"
                onChange={(e) => setProfileImg(e.target.files[0])}
                className="block w-fit text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
              />
              <button
                className="mt-5 mb-5 px-[10px] py-[5px] bg-black text-white rounded-full text-sm"
                onClick={handleChangeProfileImage}
              >
                Change Profile Image
              </button>
            </div>
            <div className="mb-5">
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                Email id*
              </label>
              <input
                type="email"
                id="email"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                value={formData.email}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="name@profitai.com"
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">
                Phone number
              </label>
              <input
                type="text"
                id="phone"
                value={formData?.phone_number}
                onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                disabled
                className="opacity-75 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter phone number"
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="business_name" className="block mb-2 text-sm font-medium text-gray-900">
                Customer Name*
              </label>
              <input
                type="text"
                id="business_name"
                value={formData.customer_name}
                onChange={(e) => setFormData({ ...formData, customer_name: e.target.value })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter your business name"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900">
                Zipcode*
              </label>
              <div className="flex mb-5 gap-[10px]">
                <div className="relative z-0 w-full group">
                  <input
                    type="zipcode"
                    onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                    id="zipcode"
                    value={formData.zipcode}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter zipcode"
                    required
                  />
                </div>
                <div className="relative z-0 group">
                  <button
                    type="button"
                    onClick={handleSearchZipcode}
                    className="text-white rounded-[5px] bg-black h-full px-[20px] py-[8px] text-center"
                  >
                    Find
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="flex space-x-[10px] mb-5">
              <div className="w-1/2">
                <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900">
                  State
                </label>
                <input
                  type="state"
                  id="state"
                  value={formData.state}
                  onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter state"
                  required
                />
              </div>
              <div className="w-1/2">
                <label htmlFor="city" className="ml-2 block mb-2 text-sm font-medium text-gray-900">
                  City
                </label>
                <input
                  type="city"
                  id="city"
                  value={formData.city}
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter city"
                  required
                />
              </div>
            </div> */}
            <div className="mb-5">
              <label htmlFor="address1" className="block mb-2 text-sm font-medium text-gray-900">
                Address * (Max 250 Characters)
              </label>
              <textarea
                id="address1"
                rows="2"
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                value={formData.address}
                maxLength={250}
                placeholder="Address..."
              ></textarea>
            </div>
            {/* <div className="mb-5">
              <label htmlFor="address2" className="block mb-2 text-sm font-medium text-gray-900">
                Address 2 (Max 250 Characters)
              </label>
              <textarea
                id="address2"
                rows="2"
                onChange={(e) => setFormData({ ...formData, address2: e.target.value })}
                className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                value={formData.address2}
                maxLength={250}
                placeholder="Address 2..."
              ></textarea>
            </div> */}
            <div className="flex justify-center py-[40px] gap-[20px]">
              <button
                // onClick={handleSubmit}
                type="submit"
                disabled={disableSubmitButton}
                className="text-white bg-black px-[20px] py-[10px] rounded-md"
              >
                Update Profile
              </button>
              {/* <button
                onClick={() => handleDeleteAccount()}
                className="text-white bg-red-500 px-[20px] py-[10px] rounded-md"
              >
                Delete Account
              </button> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
