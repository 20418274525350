import React, { useState } from "react";
// import { Box } from "@mui/material";
// import './Login.scss'; -Phaniver - todo - delete - if no dependency
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { http } from "../../service/http";
import OTPInput from "react-otp-input";
import { checkTokenExists, setSession } from "../../utils/session";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setProfileImage } from "../../reducers/Slices/userSlice";
import CreateCustomerProfileModal from "./CreateCustomerProfileModal";
import { apiUrl } from "../../config/appConfig";
import LoginBackground from "../../assets/images/login_background.jpg";
import ProfitAILogo from "../../assets/images/TheProfitAi_logo.png";
import toast from "react-hot-toast";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(0);
  const { uniqueLinkCode } = useParams();

  useEffect(() => {
    if (checkTokenExists()) {
      if (sessionStorage.getItem("customer") !== "null") {
        navigate(`/${uniqueLinkCode}/`);
        return;
      }
      setOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  //initial states of the form
  const initialState = {
    phone_number: "",
    otp: "",
    unique_link_code: uniqueLinkCode,
  };

  //initial states of the form
  const [formData, setFormData] = useState(initialState);
  // const [inputError, setInputError] = useState({ phone_number: "", otp: "" });
  const [showError, setShowError] = useState(false);

  //Disable showError after certain amount of time
  useEffect(() => {
    if (showError) {
      setTimeout(() => setShowError(false), 5000);
    }
  }, [showError]);

  const [showOtp, setShowOtp] = useState(false);

  const handleSendOtp = async () => {
    // some check validation here
    setShowOtp(true);
    setTimer(60);
    http
      .post("/online_store/customer/send-otp/", formData)
      .then((res) => {})
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleVerifyOtp = () => {
    http
      .post("/online_store/customer/verify-otp/", formData)
      .then((res) => {
        if (res?.data?.token) {
          setSession(res.data);
          //If there is a profile picture set it
          if (res.data?.profile_image) {
            dispatch(setProfileImage(`${apiUrl}${res.data?.profile_image}`));
          }
          if (res.data.customer) {
            navigate(`/${uniqueLinkCode}/`);
          } else {
            setOpen(true);
          }
        } else {
          throw res.data.message;
        }
      })
      .catch((err) => {
        setShowError(true);
      });
  };

  //Let use Edit Number
  const handleEditNumber = () => {
    setShowOtp(false);
  };

  //Split Phone number and area code
  function splitPhoneNumber(phoneNumber) {
    // phaniver - currently only working for indian number
    let areaCode = phoneNumber.slice(0, 3);
    let remainingNumber = phoneNumber.slice(3);

    return `${areaCode}-${remainingNumber}`;
  }

  return (
    <div className="h-dvh flex flex-col gap-[50px] items-center justify-center">
      <div className="absolute -z-10 h-[100vh]">
        <img src={LoginBackground} alt="login-background" className="h-[100vh] w-full" />
      </div>
      {/* dummy div for styling purposes */}
      {/* <div className="h-[50vh] w-full bg-neutral-50 absolute -z-10 bottom-0 left-0"></div> */}
      {/* logo and tagline  */}
      <div className="-mt-[50px]">
        <div className="flex flex-col justify-center items-center">
          <img src={ProfitAILogo} alt="The Profit AI logo" className="w-[280px]" />
          <span className="block text-center text-4xl text-[#FF9650] -mt-[50px] h-[50px]">Online Store</span>
        </div>
        <p className="text-xl text-center mt-[20px]">Find What You Need, Love What You Get</p>
        <p className="text-xl text-center mt-[5px]">Start your journey by signing up for free.</p>
      </div>

      {/* Create Business Modal or OTP verification*/}
      <div>
        {open ? (
          <div>
            <CreateCustomerProfileModal />
          </div>
        ) : (
          <div className="px-[100px] py-[20px] rounded-[20px] border-[1px] border-neutral-200 shadow bg-white">
            <div>
              {/* Phone Number Input */}
              {!showOtp && (
                <div className="flex flex-col justify-center">
                  <label className="block text-black text-md text-center font-medium m-[20px]" htmlFor="username">
                    Enter Your Mobile Number
                  </label>
                  <PhoneInput
                    country={"in"}
                    disabled={showOtp}
                    value={formData.phone_number}
                    onChange={(phone_number) => setFormData({ ...formData, phone_number: `+${phone_number}` })}
                    onKeyDown={(e) => e.key === "Enter" && handleSendOtp()}
                    inputStyle={{
                      width: "100%",
                      borderColor: "#E0E0E0",
                      padding: "18px 47px",
                      borderRadius: "5px",
                      color: "rgba(1, 1, 1, 0.8)",
                    }}
                  />
                </div>
              )}
              {showOtp ? (
                <>
                  <label className="text-center m-[20px] block text-base text-neutral-500" htmlFor="username">
                    We've just sent you 6 digit code to{" "}
                    <span className="text-black">{splitPhoneNumber(formData.phone_number)}</span>
                  </label>
                  <div className="w-full flex justify-center py-1 mx-auto">
                    <OTPInput
                      value={formData.otp}
                      onChange={(otp) => setFormData({ ...formData, otp })}
                      numInputs={6}
                      shouldAutoFocus={true}
                      containerStyle={{
                        borderColor: "#000 !important",
                      }}
                      inputStyle={{
                        width: "40px",
                        height: "40px",
                        border: "1px solid gray",
                      }}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input {...props} onKeyDown={(e) => e.key === "Enter" && handleVerifyOtp()} />
                      )}
                    />
                  </div>
                  {showError && (
                    <span className="text-center block py-[10px] text-red-500 text-sm">
                      OTP is either empty or Incorrect!
                    </span>
                  )}
                  <div className="text-center text-base py-2 mt-[10px] ">
                    {timer > 0 ? (
                      <p className="text-neutral-500">Resend OTP in {timer}s </p>
                    ) : (
                      <p className="text-neutral-500">
                        Didn't receive code?{" "}
                        <span onClick={handleSendOtp} className="underline cursor-pointer text-black ">
                          Resend OTP
                        </span>
                        <span className="block text-sm">Or</span>
                        <span onClick={handleEditNumber} className="underline cursor-pointer text-black">
                          Change Number
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="w-full flex gap-[20px] justify-center items-center py-[30px]">
                    <button
                      onClick={handleVerifyOtp}
                      onKeyDown={(e) => e.key === "Enter" && handleVerifyOtp}
                      className="bg-black text-white px-[20px] py-[10px] rounded-full"
                    >
                      Verify OTP
                    </button>
                  </div>
                </>
              ) : (
                <div className="w-full flex justify-center items-center py-[30px]">
                  <button
                    onClick={handleSendOtp}
                    onKeyDown={(e) => e.key === "Enter" && handleSendOtp}
                    className="bg-black text-white px-[20px] py-[10px] rounded-full"
                  >
                    Send OTP
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
